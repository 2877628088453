@import './reset.scss';
#wrapper {
	float: left; width: 100%;
	#banner {
		width: 100%; height: 60vh; 
		background: url('../images/header.jpg') center center no-repeat;
		background-size: cover; display: flex; justify-content: center;
    	align-items: center; min-height: 600px;
		#logo {
			width: 185px; height: 172px; background: url('../images/logo.svg') center center no-repeat;
			background-size: 185px 172px;
		}
	}
	#aboutus {
		text-align: center; width: 100%; padding: 120px 0 160px 0;
		.content {
			display: flex; flex-direction: column;
    		align-items: center;
		}
	}
	#impressions {
		display: flex; flex-direction: column;
		align-items: center;
		.images {
			float: left; width: 100%; margin-top: -25px;
			#impression-1, #impression-2, #impression-3 {
				width: calc(100%/3); float: left;
				padding-top: calc(100%/4); background-size: cover;
				background-repeat: no-repeat;
				background-position: center;
			}
			#impression-1 {
				background-image: url('../images/impression-1.jpg');
			}
			#impression-2 {
				background-image: url('../images/impression-2.jpg');
			}
			#impression-3 {
				background-image: url('../images/impression-3.jpg');
			}
			#impression-4 {
				background-image: url('../images/impression-4.jpg');
				width: 100%; float: left; 
				height: 50%; width: 100%; float: left;
				padding-top: calc(100%/4); background-size: cover;
				background-repeat: no-repeat;
				background-position: center;
			}
		}
	}
	#contact {
		width: 100%; float: left; padding: 80px 0;
		display: flex; justify-content: center;
		#contact-wrapper {
			max-width: 1200px; margin: auto; padding: 0 20px;
			display: flex; width: 100%;
			#contact-image {
				float: left; width: 50%; padding-top: 40%;
				background-image: url('../images/contact.jpg');
				background-repeat: no-repeat; background-size: cover;
				background-position: center;
			}
			#contact-details {
				float: left; width: 50%; padding-left: 30px; display: flex; 
				flex-direction: column; justify-content: center; align-items: flex-start;
			}
		}
	}
}

@media screen and (max-width: 780px) {
	#wrapper #impressions .images #impression-1, 
	#wrapper #impressions .images #impression-2, 
	#wrapper #impressions .images #impression-3 {
		width: 100%;
	}
	h2 {
		font-size: 32px;
		div {
			height: 15px; left: -15px; right: -15px; bottom: -15px;
		}
	}
	#wrapper #contact #contact-wrapper {
		flex-direction: column;
	}
	#wrapper #contact #contact-wrapper #contact-image {
		width: 100%; margin-bottom: 40px;
	}
	#wrapper #banner #logo {
		width: calc(185px/1.5); height: calc(172px/1.5); 
		background-size: calc(185px/1.5) calc(172px/1.5);
	}
}