/*########################################
/#####RESET ELEMENTS
########################################*/
* {
  margin: 0; padding: 0; box-sizing: border-box;
}
ul {
  list-style: none; list-style-type: none;
}
ul li {
  float: left;
}
img, fieldset {
  border: none;
}
h1, h2, h3, h4, h5, h6 {
  font-weight: normal; margin-bottom: 10px;
}

@font-face {
    font-family: 'Merriweather';
    src: url('font-files/merriweather-regular-webfont.woff2') format('woff2'),
         url('font-files/merriweather-regular-webfont.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,600;1,300;1,600&display=swap');


/*########################################
/#####MAIN ELEMENTS
########################################*/
body {
  font-family: 'Open Sans', sans-serif;
  background: #000; color: #FFF; font-size: 14px; 
  font-weight: 400; line-height: 1.5em;
  overflow-y: auto;
}
p {
  float: left; margin-bottom: 10px; width: 100%;
}
.content {
  width: 100%; max-width: 600px; margin: auto; padding: 0 20px;
}
a {
  color: #FFF;
}
h2 {
  font-size: 42px; color: #FFF; font-weight: 600; 
  font-family: 'Merriweather'; margin-bottom: 35px;
  position: relative; z-index: 500;
  span {
    position: relative; z-index: 500;
  }
  div {
    position: absolute; height: 25px; 
    left: -25px; right: -25px; bottom: -25px;
    background: #282828;
  }
}
h3 {
  font-weight: 600; margin-bottom: 0px;
}
.description {
  float: left; width: 100%; margin-bottom: 25px;
}